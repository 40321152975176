<template>
    <section
        v-if="course"
        class="course-progress-holder"
    >
        <nav>
            <ProgressText
                :course="course"
                :current-module="currentModule"
                :current-lesson="currentLesson"
            />
            <ProgressBar
                :course="course"
                :is-intro="isIntro"
                :current-module="currentModule"
                :current-lesson="currentLesson"
                :visited-module="visitedModule"
                :visited-lesson="visitedLesson"
                @click="clickNotch"
            />
        </nav>
    </section>
</template>

<script>
import ProgressText from './ProgressText';
import ProgressBar from './ProgressBar';

export default {
    name: 'ProgressHolder',
    components: {
        ProgressText,
        ProgressBar,
    },
    props: {
        course: {
            type: Object,
            default: null,
        },
        currentModule: {
            type: Number,
            default: null,
        },
        currentLesson: {
            type: Number,
            default: null,
        },
        visitedModule: {
            type: Number,
            default: null,
        },
        visitedLesson: {
            type: Number,
            default: null,
        },
        isIntro: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        clickNotch(moduleIndex, pageIndex) {
            this.$emit('click', moduleIndex, pageIndex);
        },
    },
};
</script>
