<template>
    <div
        v-if="course"
    >
        <WhiteBoard :course="course" />
    </div>
    <LoadingSpinner v-else-if="loading" />
    <div v-else>
        No course found
    </div>
</template>
<script>
import CourseService from '@/services/CourseService';
import CourseMedia from '@/common/getCourseMedia';
import WhiteBoard from '@/components/whiteboard/Whiteboard';
import LoadingSpinner from '@/components/loader/Loader';
import Bugsnag from '@bugsnag/js';

export default {
    name: 'CourseScreenWhiteboard',
    components: {
        WhiteBoard,
        LoadingSpinner,
    },
    data() {
        return {
            course: null,
            loading: true,
            id: this.$route.params.id,
        }
    },
    mounted(){
        this.getCourse();
    },
    methods: {
        async getCourse() {
            try {
                const cache = await caches.open('courses');
                const isCached = await cache.match(process.env.VUE_APP_APIURL + '/api/v1/course/' + this.id + '?cache=true');
                const response = await CourseService.getCourse(this.id, isCached);
                this.course = response.course;
                response.course.lessons = CourseMedia.sortData(response.course.lessons, true);
                this.course.lessons = CourseMedia.parseAnswers(response.course.lessons);
                document.title = this.course.name + ' | LEEA';
                this.loading = false;
            } catch (error) {
                Bugsnag.notify(error);
                this.$router.push('/courses');
            }
        }
    }
}
</script>
