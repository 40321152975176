<template>
    <div
        v-if="getModule && getLesson"
        class="course-progress-bar"
        :class="{
            offscreen: larger,
        }"
        ref="top"
    >
        <div
            class="course-progress-bar-size"
            :style="{
                width: `${width}px`
            }"
        >
            <div
                ref="slider"
                class="course-progress-bar-slider"
                :style="{
                    transform: `translateX(-${offset}px)`,
                    transition: `transform 350ms`,
                }"
            >
                <div ref="progress">
                    <div
                        v-for="(module, moduleIndex) in getCourseWithIntro"
                        :key="module.id"
                        :ref="`module-${moduleIndex}`"
                        class="course-progress-bar-module"
                        :class="{
                            intro: moduleIndex === 0,
                            active: (isIntro && moduleIndex === 0) || (!isIntro && (currentModule >= moduleIndex - 1))
                        }"
                    >
                        <div class="course-progress-bar-dot" />
                        <div
                            v-for="(page, pageIndex) in module.pages"
                            :key="page.id"
                            :ref="`page-${moduleIndex}-${pageIndex}`"
                            class="course-progress-bar-notch"
                            :class="{
                                active: (isIntro && moduleIndex === 0) || (!isIntro && (currentModule > moduleIndex - 1 || (currentModule >= moduleIndex - 1 && currentLesson >= pageIndex))),
                                clickable: isClickable(moduleIndex, pageIndex),
                            }"
                            :style="{
                                width: `${100 / module.pages.length}%`,
                            }"
                            @click="clickNotch(moduleIndex, pageIndex)"
                        />
                        <div
                            v-if="moduleIndex === getCourseWithIntro.length - 1"
                            class="course-progress-bar-dot last"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="larger" class="course-progress-bar-dot top first" />
        <div
            v-if="larger"
            class="course-progress-bar-dot top last"
            :style="{
                opacity: showLastDot ? 1 : 0,
                transition: 'opacity 350ms',
            }"
        />
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        course: {
            type: Object,
            default: null,
        },
        currentModule: {
            type: Number,
            default: null,
        },
        currentLesson: {
            type: Number,
            default: null,
        },
        visitedModule: {
            type: Number,
            default: null,
        },
        visitedLesson: {
            type: Number,
            default: null,
        },
        isIntro: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            larger: false,
            active: null,
            offset: 0,
            width: 0,
            showLastDot: false,
            resizing: false
        };
    },
    mounted() {
        this.active = this.getActive;
        this.calcWidth();
        window.addEventListener('resize', this.throttleResize());
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.throttleResize());
    },
    watch: {
        isIntro() {
            this.active = this.getActive;
            this.calcPosition(this.isIntro === false ? 'next' : 'prev');
        },
        currentModule() {
            this.active = this.getActive;
        },
        currentLesson(newValue, oldValue) {
            this.active = this.getActive;
            this.calcPosition(newValue > oldValue ? 'next' : 'prev');
        },
    },
    computed: {
        getModule() {
            return this.currentModule + 1;
        },
        getLesson() {
            return this.currentLesson + 1;
        },
        getModuleMax() {
            return this.getCourseWithIntro.length;
        },
        getLessonMax() {
            return this.getCourseWithIntro[this.currentModule].pages.length;
        },
        getCourseWithIntro() {
            return [...[{
                id: -1,
                name: 'Introduction',
                pages: [{
                    id: -1,
                }],
            }], ...this.course.data];
        },
        getActive() {
            const active = {
                module: this.$refs[`module-${this.getModule}`],
                page: this.$refs[`page-${this.getModule}-${this.currentLesson}`],
            };
            if (this.isIntro) {
                active.module = this.$refs['module-0'];
                active.page = this.$refs['page-0-0'];
            }

            if (active.module && active.module.length) active.module = active.module[0];
            if (active.page && active.page.length) active.page = active.page[0];

            return active;
        },
    },
    methods: {
        isClickable(moduleIndex, pageIndex) {
            return (this.isIntro && this.moduleIndex === 0) || (this.visitedModule > moduleIndex - 1 || (this.visitedModule >= moduleIndex - 1 && this.visitedLesson >= pageIndex));
        },
        clickNotch(moduleIndex, pageIndex) {
            if (this.isClickable(moduleIndex, pageIndex)) {
                this.$emit('click', moduleIndex - 1, pageIndex);
                this.calcWidth();
            }
        },
        throttleResize() {
            if (!this.resizing) {
                this.resizing = true;
                this.calcWidth();
            }
        },
        calcWidth() {
            const topSize = this.$refs.top.getBoundingClientRect();
            let width = 0;
            this.width = 0;
            const keys = Object.keys(this.$refs);
            keys.filter(key => key.includes('module-')).forEach(moduleKey => {
                const module = this.$refs[moduleKey][0];
                width += module.getBoundingClientRect().width;
            });

            this.larger = false;
            this.width = topSize.width;
            if (width > topSize.width) {
                this.width = width;
                this.larger = true;
                this.calcPosition();
            }
            this.resizing = false;
        },
        calcPosition(direction = 'next') {
            if (!this.larger) return;
            let topSize = this.$refs.top.getBoundingClientRect();
            let sliderSize = this.$refs.slider.getBoundingClientRect();
            let activeSize = this.active.page.getBoundingClientRect();

            activeSize = {
                element: this.active.page,
                width: activeSize.width,
                left: (sliderSize.left - activeSize.left) * -1,
                right: ((sliderSize.left - activeSize.left) - activeSize.width) * -1,
            };
            sliderSize = {
                element: this.$refs.slider,
                width: sliderSize.width,
                left: (topSize.left - sliderSize.left) * -1,
                right: ((topSize.left - sliderSize.left) - sliderSize.width) * -1,
            };
            topSize = {
                element: this.$refs.top,
                left: 0,
                right: topSize.width,
            };

            if (sliderSize.right < topSize.right && activeSize.left + sliderSize.left >= topSize.left) {
                this.offset = sliderSize.right - topSize.width;
            } else {
                this.offset = activeSize.left;
            }

            this.showLastDot = true;
            const checkAgainst = direction === 'next' ? sliderSize.right - activeSize.width : sliderSize.right + activeSize.width;
            if (!this.isIntro && checkAgainst < topSize.right) {
                this.showLastDot = false;
            }
        },
    },
};
</script>
