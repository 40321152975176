<template>
    <div class="course-whiteboard-slide flashCardBlock">
        <div v-if="layout === 'stack'" class="d-flex flex-column mx-3">
            <div class="hooper__container">
                <b-button
                    variant="primary"
                    class="sliderBtn sliderBtn--prev"
                    @click="$refs[`flashcard-slider-${blockIdx}`].slidePrev()"
                >
                    <svg-icon icon="chevron-left"/>
                </b-button>
                <hooper
                    :ref="`flashcard-slider-${blockIdx}`"
                    :settings="hooperSettings"
                    @slide="updateSlide"
                >
                    <slide v-for="(card, idx) in cards" :key="idx" :index="idx">
                        <flash-card
                            :id="idx"
                            :ref="`flashcard-${blockIdx}`"
                            :front="card.cardTitle"
                            :front-image="card.frontImage"
                            :card-class="colour"
                            class="stack"
                            :flip-on-click="false"
                            :interact="true"
                            @interacted="interacted"
                        >
                            <template #front >
                                <div class="body__content">
                                    {{card.cardTitle}}
                                </div>
                            </template>
                            <template #back>
                                <div class="body__content" v-html="card.cardDescription" />
                            </template>
                            <template #footer>
                                <div
                                    class="vue2-flashcard__footer">
                                    <button class="flashbtn flashbtn--flip" @click.stop="$refs[`flashcard-${blockIdx}`][idx].flipCard()">
                                        <svg-icon icon="flip" />
                                    </button>
                                </div>
                            </template>
                        </flash-card>
                    </slide>
                </hooper>
                <b-button
                    variant="primary"
                    class="sliderBtn sliderBtn--next"
                    @click="$refs[`flashcard-slider-${blockIdx}`].slideNext()"
                >
                    <svg-icon icon="chevron-right"/>
                </b-button>
            </div>
            <div class="pagination">
                <button
                    v-for="(card, idx) in cards"
                    :key="`pager-${idx}`"
                    class="pagination__dot"
                    :class="{'pagination__dot--active': activeSlide === idx}"
                    @click="$refs[`flashcard-slider-${blockIdx}`].slideTo(idx)"
                >
                    <span />
                </button>

            </div>
        </div>
        <div v-else-if="layout === 'grid'" class="d-flex justify-content-center flex-wrap">
            <flash-card
                v-for="(card, idx) in cards"
                :id="idx"
                :key="idx"
                :ref="`flashcard-${blockIdx}`"
                :front="card.cardTitle"
                :front-image="card.frontImage"
                :back="card.cardDescription"
                :card-class="colour"
                class="grid"
                :flip-on-click="false"
                :interact="true"
                @interacted="interacted"
            >
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope"/>
                </template>

                <template #front>
                    <div class="body__content">
                        {{card.cardTitle}}
                    </div>
                </template>

                <template #back>
                    <div class="body__content text-center" v-html="card.cardDescription" />
                </template>
                <template #footer>
                    <div
                        class="vue2-flashcard__footer">
                        <button class="flashbtn flashbtn--flip" @click.stop="$refs[`flashcard-${blockIdx}`][idx].flipCard()">
                            <svg-icon icon="flip" />
                        </button>
                    </div>
                </template>
            </flash-card>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
import FlashCard from 'vue2-flashcard';
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'FlashInformationBlock',
    components: {
        FlashCard,
        Hooper,
        Slide,
        SvgIcon
    },
    mixins: [
        CourseBlockMixin,
    ],

    props: {
        active: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            blockTmp: {...this.blockData},
            activeSlide: 0,
            hooperSettings: {
                itemsToShow: 1,
                centerMode: true,
                wheelControl: false,
                touchDrag: false,
                mouseDrag: false,
            }
        };
    },
    computed: {
        blockIdx() {
            if (this.slideNum){
                return this.slideNum
            } else {
                return '' + this.module + this.lesson + this.id
            }
        },
        cards() {
            if (this.blockData.flash_information) {
                return this.blockData.flash_information.carouselCards;
            }
            return this.blockData.cards;
        },
        colour() {
            if (this.blockData.flash_information) {
                return this.blockData.flash_information.card_color;
            }
            return this.blockTmp.colour;
        },
        layout() {
            if (this.blockData.flash_information) {
                return this.blockData.flash_information.isCard_view;
            }
            return this.blockData.layout;
        },
    },
    methods:{
        updateSlide({currentSlide}) {
            this.activeSlide = currentSlide;
        },
        interacted(idx){
            this.$emit('interacted', this.id, idx);
        }
    },
};
</script>
