<template>
    <div class="container course-screen min-full d-flex flex-column align-items-center justify-content-center">
        <b-link class="back" to="/courses/"><svg-icon icon="back" /></b-link>
        <div v-if="course && !loading">
            <div v-if="isIntro" class="course-introduction">
                <div class="course-introduction-box" :class="textDir">
                    <img :src="course.course_image" style="max-width: 100%;" />
                    <h1 class="mt-4 mb-4 pl-4 pr-4 text-center">{{course.name}}</h1>
                    <p class="'text-area pl-4 pr-4" v-html="course.description" />
                </div>
                <button type="button" @click="startCourse" class="btn p-3 mt-3 btn-primary btn-block">{{$t('startCourse')}}</button>
            </div>
            <div v-else>
                <h1>{{currentModule.name}}</h1>
                <div :class="textDir">
                    <h2 class="text-center">{{currentLesson.page_name}}</h2>
                    <CourseBlock
                        v-for="(block, blockIndex) in currentLesson.page_data"
                        :key="'' + moduleIdx + lessonIdx + blockIndex"
                        :data="currentLesson.page_data[blockIndex]"
                        :module="moduleIdx"
                        :textDir="textDir"
                        :lesson="lessonIdx"
                        :id="blockIndex"
                        @submitAnswers="submitAnswers"
                        @interacted="interacted"
                    />
                </div>
                <div class="d-flex justify-content-between">
                    <button
                        v-if="!isIntro"
                        type="button"
                        @click="prevLesson"
                        class="btn p-3 mt-3 mx-3 btn-primary btn-block"
                    >{{$t('prevLesson')}}</button>
                    <button
                        v-if="!(moduleIdx === course.data.length - 1 && lessonIdx === currentModule.pages.length - 1)"
                        type="button"
                        :disabled="!fullyInteracted"
                        @click="nextLesson"
                        class="btn p-3 mt-3 mx-3 btn-primary btn-block"
                    >{{$t('nextLesson')}}</button>
                    <button
                        v-else
                        type="button"
                        :disabled="!fullyInteracted && !loading"
                        @click="finishCourse"
                        class="btn p-3 mt-3 mx-3 btn-primary btn-block"
                    >{{$t('finish')}}</button>

                </div>
            </div>
            <Progress
                :course="course"
                :is-intro="isIntro"
                :current-module="moduleIdx"
                :current-lesson="lessonIdx"
                :visitedModule="moduleIdxTrace"
                :visitedLesson="lessonIdxTrace"
                @click="progressClick"
            />
            
        </div>
        <LoadingSpinner v-if="loading" />
    </div>
</template>

<script>

import CourseService from '@/services/CourseService';
import CourseMedia from '@/common/getCourseMedia';
import CourseBlock from '@/components/courses/CourseBlock.vue';
import Progress from '@/components/courses/progress/Progress';
import BrandingMixin from '@/mixins/BrandingMixin';
import SvgIcon from '@/components/SvgIcon';
import Bugsnag from '@bugsnag/js';
import LoadingSpinner from '@/components/loader/Loader';

export default {
    name: 'CourseScreen',
    components: {
        CourseBlock,
        Progress,
        SvgIcon,
        LoadingSpinner
    },
    data(){
        return {
            course: null,
            id: this.$route.params.id,
            isIntro: true,
            moduleIdx: 0,
            lessonIdx: 0,
            moduleIdxTrace: 0,
            lessonIdxTrace: 0,
            courseDataReturn: [],
            fullyInteracted: false,
            loading: true,
            cacheData: null
        }
    },
    mixins: [
        BrandingMixin
    ],
    mounted(){
        this.getCompanyBranding();
        this.getCourse().then(() => {
            if (this.course && (this.course.status !== 'Submitted' && this.course.status !== 'Failed' && this.course.status !== 'Passed') && this.course.page_ids && this.course.page_ids.length) {
                const lessonID = parseInt(this.course.page_ids.at(-1));
                const index = this.findIndexFromLesson(lessonID);
                if (index !== false) {
                    this.isIntro = false;
                    this.goToLesson(index.lesson, index.module);
                    this.nextLesson();
                }
            }
        });
    },
    computed: {
        textDir() {
            if (this.course.is_rtl !== undefined){
                if (this.course.is_rtl){
                    return 'rtl';
                } else {
                    return 'ltr';
                }
            } else {
                return '';
            }
        },
        currentModule() {
            return this.course.data[this.moduleIdx];
        },
        currentLesson() {
            return this.currentModule.pages[this.lessonIdx];
        }
    },
    methods: {
        async getCourse() {
            try {
                const cache = await caches.open('courses');
                const isCached = await cache.match(process.env.VUE_APP_APIURL + '/api/v1/course/' + this.id + '?cache=true');
                const response = await CourseService.getCourse(this.id, isCached);
                this.cacheData = response;
                this.course = response.course;
                this.courseDataReturn = response.course.response_data;
                let data = response.course.response_data;
                data = CourseMedia.sortData(data, false);
                this.course.data = CourseMedia.parseAnswers(data);
                document.title = this.course.name + ' | LEEA';
                this.$store.dispatch('toasting', '');
                this.loading = false;
            } catch(error) {
                Bugsnag.notify(error);
                this.$router.push('/courses');
            }
        },

        startCourse() {
            this.isIntro = false;
            if (!this.cacheData.course.is_started){
                this.cacheData.course.is_started = 1;
            }
            CourseService.startCourse(this.course.id, this.cacheData);
            window.scrollTo(0,0);
            this.checkInteracted();
        },
        
        prevLesson() {
            if (this.moduleIdx === 0 && this.lessonIdx === 0){
                this.isIntro = true;
            } else {
                let newLesson = this.lessonIdx - 1;
                let newModule = null;
                if (this.lessonIdx === 0) {
                    newModule = this.moduleIdx - 1;
                    newLesson = this.course.data[this.moduleIdx - 1].pages.length - 1;
                }
                this.goToLesson(newLesson, newModule);
            }
        },

        nextLesson() {
            if (!this.cacheData.course.page_ids){
                this.cacheData.course.page_ids = [];
            }
            if (this.cacheData.course.page_ids.indexOf('' + this.currentLesson.id) === -1){
                this.cacheData.course.page_ids.push('' + this.currentLesson.id);
            }
            CourseService.completeLesson(this.course.id, this.currentLesson.id, this.cacheData);
            let newLesson  = this.lessonIdx + 1;
            let newModule = null;

            if (this.lessonIdx === this.currentModule.pages.length - 1) {
                newLesson = 0;
                newModule = this.moduleIdx + 1;
            }
            this.goToLesson(newLesson, newModule);
        },

        goToLesson(newLesson, newModule) {
            if (newModule !== null) {
                this.moduleIdx = newModule;
            }
            this.lessonIdx = newLesson;
            if (this.moduleIdx >= this.moduleIdxTrace) {
                if (this.moduleIdx > this.moduleIdxTrace || this.lessonIdx > this.lessonIdxTrace) {
                    this.lessonIdxTrace = this.lessonIdx;
                }
                this.moduleIdxTrace = this.moduleIdx;
            }
            this.checkInteracted();
            window.scrollTo(0,0);
        },

        async finishCourse() {
            this.loading = true;
            if (this.course.status === 'Not Submitted') {
                await CourseService.finishCourse(this.course.id, this.cacheData);
            }
            if (this.cacheData.course.status !== 'Submitted'){
                this.cacheData.course.status = 'Submitted';
            }
            this.$router.push('/courses');
        },

        progressClick(moduleIndex, pageIndex) {
            if (moduleIndex === -1) {
                this.goToLesson(0, 0);
                this.isIntro = true;
            } else {
                this.goToLesson(pageIndex, moduleIndex);
                this.isIntro = false;
            }
		},
        submitAnswers(quizIndex, multiple, answers){
            if (multiple) {
                this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = answers;
				this.courseDataReturn[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = answers;
            } else {
                this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = [answers];
                this.courseDataReturn[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = [answers];
            }
            this.cacheData.course.response_data = this.courseDataReturn;
			CourseService.submitQuiz(this.$route.params.id, this.courseDataReturn, this.cacheData);
            this.checkInteracted();
        },
        checkInteracted() {
            if (this.course.page_ids && this.course.page_ids.indexOf(('' + this.currentLesson.id)) > -1){
                this.fullyInteracted = true;
            } else {
                let pendingInteraction = false;
                blocks:
                for (const block of this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data) {
                    switch (block.type) {
                        case 'tabs_block':
                            for (const tab of block.tabs_information) {
                                if (!tab.viewed){
                                    pendingInteraction = true;
                                    break blocks;
                                }
                            }
                            break;
                        case 'hotspot_block':
                            for (const hotspot of block.hotspot_information.hotspotTooltip) {
                                if (!hotspot.viewed){
                                    pendingInteraction = true;
                                    break blocks;
                                }
                            }
                            break;
                        case 'accordian_information_block':
                            for (const accordian of block.accordian_information) {
                                if (!accordian.viewed){
                                    pendingInteraction = true;
                                    break blocks;
                                }
                            }
                            break;
                        case 'flash_information_block':
                            if (block.cards){
                                for (const flash of block.cards) {
                                    if (!flash.viewed){
                                        pendingInteraction = true;
                                        break blocks;
                                    }
                                } 
                            } else {
                                for (const flash of block.flash_information.carouselCards) {
                                    if (!flash.viewed){
                                        pendingInteraction = true;
                                        break blocks;
                                    }
                                } 
                            }
                            break;
                        case 'quiz':
                            if (!block.questions[0].answers){
                                pendingInteraction = true;
                                break blocks;
                            }
                            break;
                    }
                }
                this.fullyInteracted = !pendingInteraction
            }
        },
        interacted(id, identifier) {
            const type = this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].type;
            switch (type) {
                case 'tabs_block':
                    this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].tabs_information[identifier].viewed = true;
                    break;
                case 'hotspot_block':
                    this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].hotspot_information.hotspotTooltip[identifier].viewed = true;
                    break;
                case 'accordian_information_block':
                    this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].accordian_information[identifier].viewed = true;
                    break;
                case 'flash_information_block':
                    if (this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].cards){
                        this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].cards[identifier].viewed = true;
                    } else {
                        this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].flash_information.carouselCards[identifier].viewed = true;
                    }
                    break;
            }
            this.checkInteracted();
        },
        findIndexFromLesson(lessonID) {
            let returnIDs = false;
            this.course.data.forEach((module, indexModule) => {
                module.pages.forEach((page, indexPage) => {
                    if (page.id === lessonID) {
                        returnIDs = {
                            module: indexModule,
                            lesson: indexPage,
                        };
                    }
                });
            });

            return returnIDs;
        },
    }

}

</script>
