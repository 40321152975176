var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getModule && _vm.getLesson)?_c('div',{ref:"top",staticClass:"course-progress-bar",class:{
        offscreen: _vm.larger,
    }},[_c('div',{staticClass:"course-progress-bar-size",style:({
            width: `${_vm.width}px`
        })},[_c('div',{ref:"slider",staticClass:"course-progress-bar-slider",style:({
                transform: `translateX(-${_vm.offset}px)`,
                transition: `transform 350ms`,
            })},[_c('div',{ref:"progress"},_vm._l((_vm.getCourseWithIntro),function(module,moduleIndex){return _c('div',{key:module.id,ref:`module-${moduleIndex}`,refInFor:true,staticClass:"course-progress-bar-module",class:{
                        intro: moduleIndex === 0,
                        active: (_vm.isIntro && moduleIndex === 0) || (!_vm.isIntro && (_vm.currentModule >= moduleIndex - 1))
                    }},[_c('div',{staticClass:"course-progress-bar-dot"}),_vm._l((module.pages),function(page,pageIndex){return _c('div',{key:page.id,ref:`page-${moduleIndex}-${pageIndex}`,refInFor:true,staticClass:"course-progress-bar-notch",class:{
                            active: (_vm.isIntro && moduleIndex === 0) || (!_vm.isIntro && (_vm.currentModule > moduleIndex - 1 || (_vm.currentModule >= moduleIndex - 1 && _vm.currentLesson >= pageIndex))),
                            clickable: _vm.isClickable(moduleIndex, pageIndex),
                        },style:({
                            width: `${100 / module.pages.length}%`,
                        }),on:{"click":function($event){return _vm.clickNotch(moduleIndex, pageIndex)}}})}),(moduleIndex === _vm.getCourseWithIntro.length - 1)?_c('div',{staticClass:"course-progress-bar-dot last"}):_vm._e()],2)}),0)])]),(_vm.larger)?_c('div',{staticClass:"course-progress-bar-dot top first"}):_vm._e(),(_vm.larger)?_c('div',{staticClass:"course-progress-bar-dot top last",style:({
            opacity: _vm.showLastDot ? 1 : 0,
            transition: 'opacity 350ms',
        })}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }