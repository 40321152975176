import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "./store"

import MainDashboard from './views/MainDashboard'

import CoursesScreen from './views/CoursesScreen'
import CourseScreen from './views/CourseScreen'
import CourseScreenWhiteboard from './views/CourseScreenWhiteboard'

import AssessmentsScreen from './views/AssessmentsScreen'
import AssessmentScreen from './views/AssessmentScreen'

import LoginScreen from './views/LoginScreen'
import TranslateScreen from './views/TranslateScreen'
import HelpSupportScreen from './views/HelpSupportScreen'

Vue.use(VueRouter);

const checkAuth = function(to, from, next) {
    if (store.getters.getToken){
        next();
    } else {
        next({
            path: '/login'
        });
    }
}

const checkNoAuth = function(to, from, next) {
    if (!store.getters.getToken) {
        next();
    } else {
        next({
            path: '/'
        });
    }
}

const checkLang = function (to, from, next) {
    if (store.getters.getLanguage){
        next();
    } else {
        next({
            path: '/translate'
        });
    }
}

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: MainDashboard,
            beforeEnter:checkAuth,
            children: [
                {
                    name: 'Courses',
                    path: '',
                    alias: 'courses',
                    component: CoursesScreen,
                    meta: {
                        title: 'My Courses',
                        update: true
                    }
                },
                {
                    name: 'Course',
                    path: 'courses/:id',
                    component: CourseScreen,
                    meta: {
                        update: false
                    }
                },
                {
                    name: 'Assessments',
                    path: 'assessments',
                    component: AssessmentsScreen,
                    meta: {
                        title: 'My Assessments',
                        update: true
                    }
                },
                {
                    name: 'Help & Support',
                    path: 'support',
                    component: HelpSupportScreen,
                    meta: {
                        title: 'Help & Support',
                        update: true
                    }
                },
            ]
        },
        {
            name: 'Assessment',
            path: '/assessments/:id',
            component: AssessmentScreen,
            meta: {
                update: false
            },
            beforeEnter:checkAuth
        },
        {
            name: 'Login',
            path: '/login',
            component: LoginScreen,
            meta: {
                title: 'Login',
                update: true
            },
            beforeEnter: function(to, from, next){
                checkLang(to, from, next)
                checkNoAuth(to, from, next)
            }
        },
        {
            name: 'Translate',
            path: '/translate',
            component: TranslateScreen,
            meta: {
                title: 'Translate',
                update: true
            },
        },
        {
            path: '/courses/:id/whiteboard',
            component: CourseScreenWhiteboard,
            beforeEnter:checkAuth,
            meta: {
                update: false
            },
        },
        { 
            path: '/:catchAll(.*)', 
            redirect: '/'
        }
    ]
})

export default router
