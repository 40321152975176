module.exports = {
    get AccordianInformationBlock() {
        return require('./AccordianInformationBlock').default;
    },
    get AudioBlock() {
        return require('./AudioBlock').default;
    },
    get EmbedBlock() {
        return require('./EmbedBlock').default;
    },
    get FlashInformationBlock() {
        return require('./FlashInformationBlock').default;
    },
    get HotspotBlock() {
        return require('./HotspotBlock').default;
    },
    get ImageBlock() {
        return require('./ImageBlock').default;
    },
    get ImageOverlayBlock() {
        return require('./ImageOverlayBlock').default;
    },
    get IntroBlock() {
        return require('./IntroBlock').default;
    },
    get KeyInformationBlock() {
        return require('./KeyInformationBlock').default;
    },
    get QuizBlock() {
        return require('./QuizBlock').default;
    },
    get QuoteBlock() {
        return require('./QuoteBlock').default;
    },
    get StyledListBlock() {
        return require('./StyledListBlock').default;
    },
    get StyledTextBlock() {
        return require('./StyledTextBlock').default;
    },
    get TabsBlock() {
        return require('./TabsBlock').default;
    },
    get TextBlock() {
        return require('./TextBlock').default;
    },
    get VideoBlock() {
        return require('./VideoBlock').default;
    },
}
