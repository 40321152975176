<template>
    <div class="course-progress-text">
        <div
            v-if="getModule && getModuleMax"
            class="course-progress-text-module"
        >
            {{ $t('module') }} {{ getModule }}/{{ getModuleMax }}
        </div>
        <div class="course-progress-text-sep" />
        <div
            v-if="getLesson && getLessonMax"
            class="course-progress-text-lesson"
        >
            {{ $t('lesson') }} {{ getLesson }}/{{ getLessonMax }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressText',
    props: {
        course: {
            type: Object,
            default: null,
        },
        currentModule: {
            type: Number,
            default: null,
        },
        currentLesson: {
            type: Number,
            default: null,
        },
    },
    computed: {
        getModule() {
            return this.currentModule + 1;
        },
        getLesson() {
            return this.currentLesson + 1;
        },
        getModuleMax() {
            return this.course.data.length;
        },
        getLessonMax() {
            return this.course.data[this.currentModule].pages.length;
        },
    },
};
</script>
