<template>
    <component
        :is="blockType"
        :block-data="data"
        :text-dir="textDir"
        :id="id"
        :module="module"
        :lesson="lesson"
        @submitAnswers="submitAnswers"
        @interacted="interacted"
    />
</template>

<script>
import {
    AccordianInformationBlock,
    AudioBlock,
    EmbedBlock,
    FlashInformationBlock,
    HotspotBlock,
    ImageBlock,
    ImageOverlayBlock,
    IntroBlock,
    KeyInformationBlock,
    QuizBlock,
    QuoteBlock,
    StyledListBlock,
    StyledTextBlock,
    TabsBlock,
    TextBlock,
    VideoBlock,
} from '@/components/courses/blocks';

export default {
    name: 'CourseBlock',
    components: {
        AccordianInformationBlock,
        AudioBlock,
        EmbedBlock,
        FlashInformationBlock,
        HotspotBlock,
        ImageBlock,
        ImageOverlayBlock,
        IntroBlock,
        KeyInformationBlock,
        QuizBlock,
        QuoteBlock,
        StyledListBlock,
        StyledTextBlock,
        TabsBlock,
        TextBlock,
        VideoBlock,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        textDir: {
            type: String,
            default: '',
        },
        id: {
            type: Number,
            required: true,
        },
        module: {
            type: Number,
            required: true,
        },
        lesson: {
            type: Number,
            required: true,
        }
    },

    computed: {
        blockType() {
            let blockType = this.data.type.replace(/_/g, '-');

            if (!blockType.includes('-block')) {
                blockType = `${blockType}-block`;
            }

            return blockType;
        },
    },
    methods: {
        submitAnswers(quizIndex, multiple, answers){
            this.$emit('submitAnswers', quizIndex, multiple, answers);
        },
        interacted(id, identifier){
            this.$emit('interacted', id, identifier);
        }
    }
};
</script>
