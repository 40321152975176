<template>
    <div
        class="course-whiteboard-slide quiz_block"
    >
        <div class="w-50 py-5 container h-100 overflow-auto">
            <div
                v-for="(question, qIndex) in blockData.questions"
                :key=" `quiz-${qIndex}`"
                class="quiz"
            >
                <h3 class="text-center">
                    {{question.question}}
                </h3>

                <h4 v-if="answerStatus">{{ answerStatus }}</h4>

                <b-form-checkbox-group
                    v-if="blockData.multiple"
                    v-model="quizAnswer"
                    stacked
                    :disabled="hasAnswered"
                >
                    <template v-for="option in question.options">
                        <b-form-checkbox
                            :key="option"
                            :value="option"
                            :class="blockData.showAnswer ? getStatusClass(option) : ''"
                        >
                            {{ option }}
                        </b-form-checkbox>
                    </template>
                </b-form-checkbox-group>

                <b-form-radio-group
                    v-else
                    v-model="quizAnswer"
                    :disabled="hasAnswered"
                    stacked
                >
                    <template v-for="option in question.options">
                        <b-form-radio
                            :key="option"
                            :value="option"
                            :class="blockData.showAnswer ? getStatusClass(option) : ''"
                        >
                            {{ option }}
                        </b-form-radio>
                    </template>
                </b-form-radio-group>
            </div>

            <b-button
                block
                variant="primary"
                class="p-3 quiz-submit mt-3"
                @click="checkAnswers"
                v-if="(blockData.showAnswer && !hasAnswered) || slideNum !== null"
                :disabled="quizAnswer.length === 0 ? true : false"
            >
               {{ $t('submit') }}
            </b-button>
             <b-button
                block
                variant="primary"
                class="p-3 quiz-submit"
                @click="submitAnswers"
                v-else-if="!hasAnswered"
                :disabled="quizAnswer.length === 0 ? true : false"
            >
               {{ $t('submit') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';

export default {
    name: 'QuizBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],
    props: {
        id:{
            type: Number,
            default: null
        },
        module:{
            type: Number,
            default: null
        },
        lesson:{
            type: Number,
            default: null
        },
        slideNum:{
            type: Number,
            default: null
        }
    },
    data() {
        return {
            quizAnswer: [],
            hasAnswered: false,
            answerStatus: null,
            correct: []
        };
    },
    mounted() {
        if (this.blockData.questions[0].answers){
            this.hasAnswered = true;
            if (this.blockData.multiple) {
                this.quizAnswer = this.blockData.questions[0].answers;
            } else {
                this.quizAnswer = this.blockData.questions[0].answers[0];
            }
        }
    },
    methods:{
        checkAnswers() {
            if (this.blockData.multiple) {
                this.correct = this.blockData.questionAnswers.filter(value => this.quizAnswer.includes(value));
                if (this.correct.length === 0) {
                    this.answerStatus = 'Incorrect';
                } else if (this.correct.length === this.blockData.questionAnswers.length && this.quizAnswer.length === this.blockData.questionAnswers.length) {
                    this.answerStatus = 'Correct';
                } else {
                    this.answerStatus = 'Partially Correct';
                }
            } else {
                if (this.quizAnswer === this.blockData.questionAnswers) {
                    this.answerStatus = 'Correct';
                } else {
                    this.answerStatus = 'Incorrect';
                }
            }
            if (this.slideNum === null){
                this.submitAnswers();
            }
        },
        async submitAnswers(){
            this.hasAnswered = true;
            this.blockData.questions[0].answers = this.quizAnswer;
            this.$emit('submitAnswers', this.id, this.blockData.multiple, this.quizAnswer);
        },
        getStatusClass(option) {
            if (this.hasAnswered && this.blockData.showAnswer) {
                if (this.blockData.multiple) {
                    if ((this.blockData.questionAnswers.includes(option) && this.quizAnswer.includes(option)) || (this.blockData.questionAnswers.includes(option) && !this.quizAnswer.includes(option))) {
                        return 'custom-checkbox--correct';
                    } else if (this.blockData.questionAnswers.includes(option) && !this.quizAnswer.includes(option) || !this.blockData.questionAnswers.includes(option) && this.quizAnswer.includes(option)) {
                        return 'custom-checkbox--error';
                    }

                    return '';
                }
                // Single answer
                if ((option === this.blockData.questionAnswers && this.quizAnswer === option) || (option === this.blockData.questionAnswers && this.quizAnswer !== option)) {
                    return 'custom-checkbox--correct';
                } else if (this.quizAnswer === option && option !== this.blockData.questionAnswers) {
                    return 'custom-checkbox--error';
                }

            }

            return '';
        }
    },
};
</script>
